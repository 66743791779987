/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import Rimac from "@components/sharedComponents/customersLogos/logos/Rimac.svg"
import Culqui from "@components/sharedComponents/customersLogos/logos/Culqui.svg"
import Unifef from "@components/sharedComponents/customersLogos/logos/Unifef.svg"
import Auna from "@components/sharedComponents/customersLogos/logos/Auna.svg"
import Astara from "@components/sharedComponents/customersLogos/logos/Astara.svg"
import Wally from "@components/sharedComponents/customersLogos/logos/Wally.svg"
import Proempresa from "@components/sharedComponents/customersLogos/logos/Proempresa.svg"
import Civa from "@components/sharedComponents/customersLogos/logos/Civa.svg"
import G4S from "@components/sharedComponents/customersLogos/logos/G4S.svg"
import Savar from "@components/sharedComponents/customersLogos/logos/Savar.svg"
import Escampa from "@components/sharedComponents/customersLogos/logos/Escampa.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/chatbots-para-whatsapp.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/automatizacion-whatsapp.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/confirmacion-asistencia-por-whatsapp.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/whatsapp-vs-email.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/whatsapp-sms.jpg"

// logos testimonios
import AvivaTes from "@components/sharedComponents/testimonies/images/logos/aviva.svg"
import SavarTes from "@components/sharedComponents/testimonies/images/logos/savar.svg"
import CepebanTes from "@components/sharedComponents/testimonies/images/logos/cepeban.svg"
import CasaRosselloTes from "@components/sharedComponents/testimonies/images/logos/casaRossello.svg"
import AstaraTes from "@components/sharedComponents/testimonies/images/logos/astara.svg"

//foto testimonio desktop
import avivaDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/aviva.png"
import savarDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/savar.png"
import cepebanDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/cepeban.png"
import astaraDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/astara.png"
import casaRosselloDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/casaRossello.png"

//foto testimonio desktop
import avivaMobile from "@components/sharedComponents/testimonies/images/fotosMobile/aviva.png"
import savarMobile from "@components/sharedComponents/testimonies/images/fotosMobile/savar.png"
import cepebanMobile from "@components/sharedComponents/testimonies/images/fotosMobile/cepeban.png"
import astaraMobile from "@components/sharedComponents/testimonies/images/fotosMobile/astara.png"
import casaRosselloMobile from "@components/sharedComponents/testimonies/images/fotosMobile/casaRossello.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageFunctionalities/pageWhatsappInteractivos/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageFunctionalities/pageWhatsappInteractivos/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageFunctionalities/pageWhatsappInteractivos/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageFunctionalities/pageWhatsappInteractivos/images/accordionLeft/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageInteractivo.jpg"

import { TitleUnderlineWhatsappInteractivosOne, TitleUnderlineWhatsappInteractivosTwo } from "@components/pageFunctionalities/components/intro/titleUnderlineWhatsappInteractivos"
import imageStartWhatsappInteractivos from "@components/pageFunctionalities/components/intro/images/imageStartWhatsappInteractivos.webp"

import ImageVerticalOne from "@components/pageFunctionalities/pageWhatsappInteractivos/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalTwo from "@components/pageFunctionalities/pageWhatsappInteractivos/images/imagesNavbarVertical/imageTwo.webp"
import ImageVerticalThree from "@components/pageFunctionalities/pageWhatsappInteractivos/images/imagesNavbarVertical/imageThree.webp"
import ImageVerticalFour from "@components/pageFunctionalities/pageWhatsappInteractivos/images/imagesNavbarVertical/imageFour.webp"
import ImageVerticalFive from "@components/pageFunctionalities/pageWhatsappInteractivos/images/imagesNavbarVertical/imageFive.webp"
import ImageVerticalSix from "@components/pageFunctionalities/pageWhatsappInteractivos/images/imagesNavbarVertical/imageSix.webp"

//image indrustias
import banca from "@components/pageFunctionalities/pageWhatsappInteractivos/industries/images/banca.png"
import salud from "@components/pageFunctionalities/pageWhatsappInteractivos/industries/images/salud.png"
import ecommerce from "@components/pageFunctionalities/pageWhatsappInteractivos/industries/images/ecommerce.png"
import educacion from "@components/pageFunctionalities/pageWhatsappInteractivos/industries/images/educacion.png"
import concesionaria from "@components/pageFunctionalities/pageWhatsappInteractivos/industries/images/concesionaria.png"
import inmobiliaria from "@components/pageFunctionalities/pageWhatsappInteractivos/industries/images/inmobiliaria.png"

const titleStart = (
  <p>
    <TitleUnderlineWhatsappInteractivosOne underline="Crea interacciones" />  enriquecidas por WhatsApp mediante mensajes interactivos
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Mejora la comunicación con tus clientes por esta app añadiendo botones, lista de mensajes y mensajes de productos.
        <br /><br />
        Mejora la experiencia de tus clientes permitiendo interactuar con el contenido de tus mensajes de una forma más dinámica.
      </p>
    ),
    image: imageStartWhatsappInteractivos,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    Rimac,
    Culqui,
    Unifef,
    Auna,
    Astara,
    Wally,
    Proempresa,
    Civa,
    G4S,
    Savar,
    Escampa,
    Royal
  ],
  customersTablet: {
    arrayOne: [Rimac, Culqui, Unifef, Auna, Astara, Wally],
    arrayTwo: [Proempresa, Civa, G4S, Savar, Escampa, Royal],
  },
  articles: [
    {
      image: article1,
      title: "5 razones para implementar chatbots para WhatsApp en tu negocio",
      textbody:
        "Conoce qué son los chatbots para WhatsApp y empieza a optimizar la atención al cliente digital y las ventas de tu negocio.",
      link: "https://beexcc.com/blog/chatbots-para-whatsapp/",
      card: true,
    },
    {
      image: article2,
      title: "¿Cómo implementar la automatización de WhatsApp en simples pasos?",
      textbody:
        "Descubre qué es la automatización de WhatsApp y cómo implementarlo con estos pasos y consejos. ¡ENTRA AQUÍ y eleva la productividad de tus equipos!",
      link: "https://beexcc.com/blog/automatizacion-whatsapp/",
      card: true,
    },
    {
      image: article3,
      title: "Confirmación de asistencia por WhatsApp: organiza tus eventos con estos pasos y consejos",
      textbody:
        "Descubre qué es la confirmación de asistencia por WhatsApp. ¡ENTRA AQUÍ y prepara con éxito tus eventos, desde citas médicas hasta capacitaciones!",
      link: "https://beexcc.com/blog/confirmacion-asistencia-por-whatsapp/",
    },
    {
      image: article4,
      title: "WhatsApp vs Email marketing: ¿Cuál tiene un mayor impacto?",
      textbody:
        "Creamos un WhatsApp vs Email para conocer sus fortalezas y debilidades de cada uno y así sepas cuál es el ideal para tu empresa.",
      link: "https://beexcc.com/blog/whatsapp-vs-email/",
    },
    {
      image: article5,
      title: "WhatsApp vs SMS: ¿Cuál es más efectivo para tu empresa?",
      textbody:
        "Creamos un WhatsApp vs SMS para conocer sus fortalezas y debilidades de cada uno y así sepas cuál es el ideal para tu empresa.",
      link: "https://beexcc.com/blog/whatsapp-sms/",
    },
  ],
  contacts: {
    title: "Empieza a conectar con todos tus clientes por WhatsApp de una forma personalizada",
    textbody: "Déjanos contarte como puedes mejorar increíblemente la comunicación y fidelización de tu negocio o empresa con nuestra solución.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Civanzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: AstaraTes,
      title:
        "Con envíos masivos de WhatsApp logramos reducir el esfuerzo de remarketing en 93%",
      textbody:
        "Astara, concesionaria de automóviles con presencia en Latam, ha automatizado sus procesos de marketing y ventas para canal orgánico y de paga con WhatsApp API.",
      name: "Jonny Castillo",
      post: "Jefe Comercial",
      link: "/casos-de-exito/astara/",
      imgDesktop: astaraDesktop,
      imgMobile: astaraMobile,
      metrics: [
        {
          metric: "x3",
          descripcion: "aumentó sus ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "93%",
          descripcion: "redujo su esfuerzo para remarketing",
          color: "#5831D8",
        },
        {
          metric: "80%",
          descripcion: "mejoró contactabilidad con leads",
          color: "#00CA56",
        },
      ],
    },
    {
      image: SavarTes,
      title:
        "Con WhatsApp Business API hemos reducido en 30% las consultas repetitivas de clientes",
      textbody:
        "Savar, empresa líder en logística, ha mejorado sus métricas más importantes de satisfacción notificando sobre el estado de sus envíos por WhatsApp.",
      name: "Dinkar Rios Macedo",
      post: "Jefe de Sistemas",
      link: "/casos-de-exito/savar/",
      imgDesktop: savarDesktop,
      imgMobile: savarMobile,
      metrics: [
        {
          metric: "+20%",
          descripcion: "aumentó la satisfacción de sus clientes",
          color: "#FFB800",
        },
        {
          metric: "+30%",
          descripcion: "se redujeron las consultas repetitivas",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    {
      image: AvivaTes,
      title:
        "Con los workflows de WhatsApp mejoramos en +30% el tiempo de agenda de citas",
      textbody:
        "Aviva, parte del grupo Intercorp, clínica multiespecialidad con diferentes servicios en salud, ha logrado automatizar su proceso de citas médicas con chatbots en WhatsApp.",
      name: "Luis Ordinola",
      post: "Coordinador de mejora de procesos",
      link: "/casos-de-exito/clinica-aviva/",
      imgDesktop: avivaDesktop,
      imgMobile: avivaMobile,
      metrics: [
        {
          metric: "+30%",
          descripcion: "mejoró el tiempo de agenda de citas",
          color: "#FFB800",
        },
        {
          metric: "+20%",
          descripcion: "ahora representa WhatsApp como canal para agendar citas",
          color: "#5831D8",
        },
      ],
    },
    {
      image: CepebanTes,
      title: "Con Beex y el API de WhatsApp mejoramos nuestra tasa de conversión de leads en +27%",
      textbody:
        "CEPEBAN, instituto especializado en banca y finanzas, ha aumentado a +1.5k la cantidad de leads mejor prospectados con chatbots por WhatsApp Business API.",
      name: "Alan De La Cruz",
      post: "Jefe de Sistemas",
      link: "/casos-de-exito/cepeban/",
      imgDesktop: cepebanDesktop,
      imgMobile: cepebanMobile,
      metrics: [
        {
          metric: "+27%",
          descripcion: "mejoró su tasa de conversión con sus leads",
          color: "#FFB800",
        },
        {
          metric: "+1.5k",
          descripcion: "leads prospectados al mes por chatbots",
          color: "#5831D8",
        },
        {
          metric: "25%",
          descripcion: "mejoró el tiempo de resolución de casos",
          color: "#00CA56",
        },
      ],
    },
    {
      image: CasaRosselloTes,
      title:
        "Ayudamos más rápido a nuestros clientes con nuestro bot, por el canal que prefieran",
      textbody:
        "Casa Rosselló, compañía con más de 150 años de historia en el sector acabados y construcción, prospecta de forma más óptima con WhatsApp API y livechat.",
      name: "Marlen Vega",
      post: "Jefa de Marketing",
      link: "/casos-de-exito/casa-rossello/",
      imgDesktop: casaRosselloDesktop,
      imgMobile: casaRosselloMobile,
      metrics: [
        {
          metric: "+75%",
          descripcion: "aumento de leads digitales",
          color: "#FFB800",
        },
        {
          metric: "+65%",
          descripcion: "aumento de recompra",
          color: "#5831D8",
        },
      ],
    },
    {
      title: "conoce",
      metrics: [
      ],
    },
  ],
  accordionRight: [
    {
      title: "Automatizaciones con Workflows",
      description: "<p>Automatiza y gestiona flujos conversación por WhatsApp para la interacción con el cliente, desde el primer contacto hasta el servicio postventa, asegurando una experiencia coherente y eficiente en cada paso.</p>",
      image: ImageAccordionLeftOne
    },  
    {
      title: "IA en WhatsApp",
      description: "<p>Crea respuestas únicas para cada usuario utilizando inteligencia artificial. Crea chatbots potenciados por IA con ChatGPT y clasifica y segmenta a tus clientes por intensiones de compra en base a las conversaciones que surgen en una atención.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Mensajes Masivos",
      description: "<p>Utiliza WhatsApp para enviar notificaciones, actualizaciones de productos, o comunicaciones de marketing a gran escala, pero personalizadas según los intereses y comportamientos de los usuarios, aumentando la relevancia y eficacia de los mensajes.</p>",
      image: ImageAccordionLeftFour
    },
    {
      title: "Integración con Herramientas Externas",
      description: "<p>Integra WhatsApp con herramientas externas como CRM o ERP para enviar mensajes altamente personalizados y relevantes, asegurando que cada comunicación sea específica para el historial, preferencias, y comportamiento de compra del cliente.</p>",
      image: ImageAccordionLeftTwo
    },
  ],
  bannerVideo: {
    title: "Cómo crear mensajes interactivos en WhatsApp en simples paso",
    description: "<p>Los mensajes interactivos por WhatsApp ofrecen a las empresas una poderosa herramienta para enriquecer la comunicación con los clientes. <br /><br /> Nuestro Marketing Manager, Jorge Bautista, nos enseña cómo puedes configurar este tipo de mensajes y cómo es que los usuarios pueden interactuar con ellos. </p>",
    image: imageVideo
  },
  navbarVerticalRight: [
    {
      tab: "1",
      title: "Citas y Reservaciones",
      text: (
        <p>
          Facilita a los clientes la reserva de citas o servicios directamente a través de WhatsApp, con confirmaciones instantáneas y recordatorios automáticos, simplificando la logística y mejorando la satisfacción del cliente.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Preguntas Frecuentes",
      text: (
        <p>
          Configura un sistema de respuestas automáticas para las preguntas más comunes, ofreciendo a los clientes respuestas instantáneas y reduciendo la carga sobre el equipo de soporte.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Selección de Métodos de Pago",
      text: (
        <p>
          Ofrece a los clientes la opción de seleccionar su método de pago preferido para transacciones dentro de WhatsApp, simplificando el proceso de compra y aumentando las tasas de conversión.
        </p>
      ),
      image: ImageVerticalThree,
    },
  ],
  navbarVerticalLeft: [
    {
      tab: "4",
      title: "Encuestas de Satisfacción",
      text: (
        <p>
          Realiza encuestas de satisfacción post-interacción para recoger feedback valioso de los clientes directamente a través de WhatsApp, permitiendo a la empresa ajustar rápidamente sus servicios o productos.
        </p>
      ),
      image: ImageVerticalFour,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "5",
      title: "Venta de Productos",
      text: (
        <p>
          Habilita un catálogo de productos dentro de WhatsApp, permitiendo a los clientes explorar y comprar productos directamente desde la plataforma, lo que crea una experiencia de compra fluida y conveniente.
        </p>
      ),
      image: ImageVerticalFive,
    },
    {
      tab: "6",
      title: "Soporte al Cliente",
      text: (
        <p>
          Mejora el soporte al cliente ofreciendo asistencia a través de WhatsApp, lo que permite resolver dudas o problemas de forma rápida y eficiente, manteniendo altos niveles de satisfacción del cliente.
        </p>
      ),
      image: ImageVerticalSix,
    },
  ],
  navbarVerticalSilder: [
    {
      tab: "1",
      title: "Citas y Reservaciones",
      text: (
        <p>
          Facilita a los clientes la reserva de citas o servicios directamente a través de WhatsApp, con confirmaciones instantáneas y recordatorios automáticos, simplificando la logística y mejorando la satisfacción del cliente.
        </p>
      ),
      image: ImageVerticalOne,
      active: "container-navbar-vertical-right-navbar-menu-button-active",
    },
    {
      tab: "2",
      title: "Preguntas Frecuentes",
      text: (
        <p>
          Configura un sistema de respuestas automáticas para las preguntas más comunes, ofreciendo a los clientes respuestas instantáneas y reduciendo la carga sobre el equipo de soporte.
        </p>
      ),
      image: ImageVerticalTwo,
    },
    {
      tab: "3",
      title: "Selección de Métodos de Pago",
      text: (
        <p>
          Ofrece a los clientes la opción de seleccionar su método de pago preferido para transacciones dentro de WhatsApp, simplificando el proceso de compra y aumentando las tasas de conversión.
        </p>
      ),
      image: ImageVerticalThree,
    },
    {
      tab: "4",
      title: "Encuestas de Satisfacción",
      text: (
        <p>
          Realiza encuestas de satisfacción post-interacción para recoger feedback valioso de los clientes directamente a través de WhatsApp, permitiendo a la empresa ajustar rápidamente sus servicios o productos.
        </p>
      ),
      image: ImageVerticalFour,
    },
    {
      tab: "5",
      title: "Venta de Productos",
      text: (
        <p>
          Habilita un catálogo de productos dentro de WhatsApp, permitiendo a los clientes explorar y comprar productos directamente desde la plataforma, lo que crea una experiencia de compra fluida y conveniente.
        </p>
      ),
      image: ImageVerticalFive,
      active: "container-navbar-vertical-left-navbar-menu-button-active",
    },
    {
      tab: "6",
      title: "Soporte al Cliente",
      text: (
        <p>
          Mejora el soporte al cliente ofreciendo asistencia a través de WhatsApp, lo que permite resolver dudas o problemas de forma rápida y eficiente, manteniendo altos niveles de satisfacción del cliente.
        </p>
      ),
      image: ImageVerticalSix,
    },
  ],
  industries: [
    {
      tab: "01. E-commerce",
      title: "Mensajes interactivos por WhatsApp para E-commerce y Retail",
      text: (
        <p>
          <br />
          Facilita la navegación y selección de productos por parte del cliente. Permite realizar consultas sobre productos, verificar disponibilidad de stock, y completar compras mediante enlaces de pago seguros, todo sin salir de la app.
          <br /> <br /> <br />
        </p>
      ),
      image: ecommerce,
      active: "container-industries-channels-navbar-menu-button-active",
      link: "/hablemos/",
      textLink: "Conoce WhatsApp para E-commerce",
    },
    {
      tab: "02. Banca y finanzas",
      title: "Mensajes interactivos por WhatsApp para Banca, Seguros, Financieras y más",
      text: (
        <p>
          <br />
          Crea un autoservicio para consultar saldos, ver historiales de transacciones, y recibir notificaciones de seguridad en tiempo real. Además, permite la realización de operaciones básicas como transferencias o pagos de servicios, ofreciendo una plataforma segura.
          <br /> <br /> <br />
        </p>
      ),
      image: banca,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp para Banca y Finanzas",
    },
    {
      tab: "03. Educación",
      title: "Mensajes interactivos por WhatsApp para Universidades, Institutos y Colegios",
      text: (
        <p>
          <br />
          Facilita la inscripción a cursos, la consulta de horarios de clases, y el acceso a material de estudio y calificaciones de manera autónoma. Además, permite a los estudiantes recibir notificaciones automáticas sobre fechas importantes, como plazos de entrega de trabajos y fechas de exámenes.
          <br /> <br /><br />
        </p>
      ),
      image: educacion,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp para Educación",
    },
    {
      tab: "04. Inmobiliarias",
      title: "Mensajes interactivos por WhatsApp para Inmobiliarias, Bienes y Raíces",
      text: (
        <p>
          <br />
          Envía de manera eficiente información detallada y fotos de propiedades, programar visitas, y responder preguntas en tiempo real. Simplifica el proceso de búsqueda y selección de inmuebles, las inmobiliarias para agilizar las negociaciones y cerrar tratos más rápidamente.
          <br /> <br /><br />
        </p>
      ),
      image: inmobiliaria,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp para Inmobiliarias",
    },
    {
      tab: "05. Salud",
      title: "Mensajes interactivos por WhatsApp para Clínicas, Hospitales y Centros de Salud",
      text: (
        <p>
          <br />
          Permite agendar citas, enviar recordatorios de medicación, y ofrecer consejos de salud personalizados. Además, de realizar consultas preliminares y recibir orientación sobre los pasos a seguir.
          <br /> <br /><br />
        </p>
      ),
      image: salud,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp para Salud",
    },
    {
      tab: "06. Concesionarias",
      title: "Mensajes interactivos por WhatsApp para Concesionarias",
      text: (
        <p>
          <br />
          Envía información sobre nuevos modelos, ofertas especiales, y recordatorios de mantenimiento. Reserva pruebas de manejo, consultas de financiamiento y disponibilidad de inventario directamente a través de WhatsApp.
          <br /> <br /><br />
        </p>
      ),
      image: concesionaria,
      link: "/hablemos/",
      textLink: "Conoce WhatsApp para Concesionarias",
    },

  ],
}

export default data
